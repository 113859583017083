import React from 'react';
import { Button, Container, Typography } from '@mui/material';

function App() {

  return (
    <Container>
      <Typography variant="h1" component="h2" gutterBottom>
        Hello
      </Typography>
    </Container>
  );
}

export default App;
